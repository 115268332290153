import Vue from 'vue';
<template>
  <div class="help-content">
    <div class="inner">
      <h2 class="title">{{ title }}</h2>
      <div class="list-wrap">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad">
            <van-cell v-for="(item, index) in lists" :key="index">
              <div :class="item.isOpen ? 'list-item active' : 'list-item'" @click="item.isOpen = !item.isOpen">
                <div class="list-head">
                  <p class="question">{{ item.question }}</p>
                </div>
                <p class="answer">{{ item.answer }}</p>
              </div>
            </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { List, PullRefresh, Cell } from 'vant'
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell)

export default {
  name: 'help-list',
  data () {
    return {
      title: '常见问题',
      lists: [
        {
          question: '标题一',
          answer: '由各种物质组成的巨型球状天体，叫做星球。星球有一定的形状，有自己的运行轨道。',
          isOpen: false
        },
        {
          question: '标题二',
          answer: '由各种物质组成的巨型球状天体，叫做星球。星球有一定的形状，有自己的运行轨道。',
          isOpen: false
        }
      ],
      finished: false,
      loading: false,
      refreshing: false,
      finishedText: '没有更多了'
    }
  },
  mounted () {
  },
  methods: {
    onLoad () {
      setTimeout(() => {
        if (this.refreshing) {
          this.lists = []
          this.refreshing = false
        }
        this.loading = false
        if (this.lists.length >= 2) {
          this.finished = true
        }
      }, 1000)
    },
    onRefresh () {
      this.finished = false
      this.loading = true
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
  .help-content{
    padding: 1rem 0.625rem 2rem;
    .inner{
      padding: 0 0.625rem;
      background-color: #fff;
    }
    .title{
      height: 2.75rem;
      font-size: 0.8125rem;
      line-height: 2.75rem;
      color: #999;
      border-bottom: 1px solid #e5e5e5;
    }
    .list-wrap{
      overflow: hidden;
      .van-cell{
          padding: 0;
          &::after{
            display: none;
          }
        }
      .list-item {
        border-bottom: 1px solid #e5e5e5;
        padding-bottom: 0.25rem;
        &.active{
          .list-head{
            &::after{
              transform: rotate(135deg);
            }
          }
          .answer{
            opacity: 1;
            height: auto;
            display: block;
          }
        }
        .list-head{
          line-height: 1.375rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 0.625rem;
           padding: 0.75rem 0.625rem 0.5rem 0;
          &::after{
            width: 0.5rem;
            height: 0.5rem;
            border-right: 0.125rem solid #666;
            border-top: 0.125rem solid #666;
            transform: rotate(45deg);
            transition: transform .3s;
            content: '';
          }
        }
        .question{
          font-size: 1.0625rem;
        }
        .answer{
          font-size: 0.8125rem;
          line-height: 1.0625rem;
          padding-bottom: 1rem;
          color: #999;
          height: 0;
          opacity: 0;
          display: none;
          transition: all 0.3s;
        }
      }
    }
  }
</style>
